import React, { FC, memo, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { css } from '@emotion/react';
import { Flex } from '$components/layouts';
import { SORTING_RELEVANCE } from '~/modules/filter/helpers';
import { useSite } from '~/store';
import { Popover } from '$components/elements/popover';
import { SearchField, SearchSuggestions } from '$components/elements/search';
import { useSearchFocus } from '../shared/use-search-focus';
import { SearchContentTypes } from '~/modules/search';
import { mq } from '$lib/helpers';
import { useCombinedSearchSuggestion } from '~/modules/search/combined-suggestion/use-combined-search-suggestion';
import { useSearchSuggestionFilter } from '~/modules/search/hooks/use-search-suggestion-filter';

type Props = {
    searchResultUrl?: string;
};

export const DesktopSearch: FC<Props> = memo(({ searchResultUrl }: Props) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [overlayOpen, setOverlayState] = useState(false);
    const resultUrl = `${searchResultUrl}?Search=${encodeURIComponent(searchTerm)}&Sort=${SORTING_RELEVANCE}`;
    const [filterPanelOpen, setFilterPanelOpen] = useState(false);
    const [isSearchPage, setIsSearchPage] = useState(false);
    const { query } = useRouter();

    useEffect(() => {
        if (query.Search && query.Search !== searchTerm && query?.slug?.includes('search')) {
            setSearchTerm(decodeURIComponent(query.Search.toString()));
        }
        if (!query.slug?.includes('search')) {
            setOverlayState(false);
            setSearchTerm('');
            setIsSearchPage(false);
        }
        if (query.slug?.includes('search')) {
            setIsSearchPage(true);
        }
    }, [query]);

    const { setSite } = useSite();

    const {
        data,
        articleResults,
        storeResults,
        categoryResults,
        isLoading,
        initialContent,
        initialContentTitle,
        zeroResultData,
        zeroResultTitle,
        searchWordResults,
        resultCount,
        onLoadMore,
        onSetFacets,
        productFacets,
        sortingOptions,
        loadingNextPage,
        onResetSize,
        serieResults,
    } = useCombinedSearchSuggestion(searchTerm, {
        searchType: SearchContentTypes.Products,
        overlayOpen: overlayOpen,
        isDesktop: true,
    });

    const { selectedFacets, sortingOptionsWithDefault } = useSearchSuggestionFilter({
        facets: productFacets,
        sortingOptions,
    });

    // This is disabled for now as the change to search has stopped it from working
    const { inFocus, focusIndex, sectionIndex, handleKeyPress, setIndexes, onSubmit } = useSearchFocus(
        [],
        setOverlayState,
        resultUrl,
        searchTerm,
        isSearchPage
    );

    useEffect(() => {
        setSite({ headerHideable: !overlayOpen });
    }, [overlayOpen]);

    const onReset = (event?: React.FormEvent<HTMLFormElement>) => {
        event?.stopPropagation();
        setSearchTerm('');
    };

    return (
        <Flex column grow>
            <Popover
                hPosition="center"
                enableScrollLock
                open={overlayOpen}
                contentCss={css`
                    width: 80vw;
                    margin-left: 5vw;
                    ${mq('xl')} {
                        width: 100%;
                        margin-left: initial;
                    }
                `}
                onClickOutside={() => (filterPanelOpen ? undefined : setOverlayState(false))}
                anchor={
                    <SearchField
                        searchTerm={searchTerm}
                        onChange={(value) => setSearchTerm(value)}
                        onKeyDown={handleKeyPress}
                        onFocus={() => setOverlayState(true)}
                        onSubmit={onSubmit}
                        onReset={onReset}
                    />
                }
            >
                <SearchSuggestions
                    productResults={data}
                    articleResults={articleResults}
                    storeResults={storeResults}
                    categoryResults={categoryResults}
                    searchWordResults={searchWordResults}
                    initialContent={initialContent}
                    initialContentTitle={initialContentTitle}
                    zeroResultContent={zeroResultData}
                    zeroResultContentTitle={zeroResultTitle}
                    focusIndex={inFocus ? focusIndex : undefined}
                    sectionIndex={inFocus ? sectionIndex : undefined}
                    setIndexes={setIndexes}
                    isLoading={isLoading}
                    isSearching={!!searchTerm}
                    searchTerm={searchTerm}
                    closeOverlay={() => setOverlayState(false)}
                    resultCount={resultCount}
                    onSetFacets={onSetFacets}
                    onLoadMore={onLoadMore}
                    loadingNextPage={loadingNextPage}
                    onResetSize={onResetSize}
                    serieResults={serieResults}
                    facets={productFacets}
                    // filterPanelOpen={filterPanelOpen}
                    setFilterPanelOpen={setFilterPanelOpen}
                    selectedFacets={selectedFacets}
                    sortingOptions={sortingOptionsWithDefault}
                    isDesktop
                    isSearchPage={isSearchPage}
                />
            </Popover>
        </Flex>
    );
});
